<template>
    <p v-if="skin === 'noSkin'" class="col-12 text-center mt-5">
        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" size="2xl" beat class="my-4" /><br /><br />
        We are sorry but something went wrong.
    </p>
    <template v-else-if="skin !== null && skin === 'px'">
        <!-- Pixel Aspiration -->
        <dynamic componentPath="_px" folder="skins" v-if="skin === 'px'" />
    </template>
    <dynamic componentPath="loader" folder="others" :addSkin="true" v-else-if="showloader === true || skin === null || configStore.config === null || perCallsStore.dictionaryObject === null || perCallsStore.promotionsObject === null || perCallsStore.pagesObject === null" />
    <template v-else>
        <!-- Template 1 -->
        <dynamic componentPath="_ac" folder="skins" v-if="skin === 'ac'" />
        <!-- Template 2 -->
        <dynamic componentPath="_ra" folder="skins" v-else-if="skin === 'ra'" />
        <div class="black-backdrop" @click="onBlackBackdropClick()"></div>
        <dynamic componentPath="alerts" folder="others" v-if="myStore.alertInfo" :translatedText="myStore.alertInfo.translatedText" :text="myStore.alertInfo.text" :classes="myStore.alertInfo.classes" />
        <dynamic componentPath="cookiePolicyPopUp" folder="others" v-if="myStore.showCookiePolicyPopUp" />
        <dynamic componentPath="incognitoBrowserPopUp" folder="others" v-if="myStore.showIncognitoBrowserPopUp" />
    </template>
</template>

<script>
import { getCookie, createCookie, setModalVisibility, setSideMenuVisibility, loadAnyScript } from "./helpers/helpers";
import { setRefreshTokenTimer, logOut } from "./helpers/servicesHelpers";
import { addGtmIFrame, createGtm, pushGtmEventObject, pushGtmEvent } from "./helpers/gtm_helpers";
import { getLocation, getLobbies, getBalances, getUser, getUserCampaignBonuses, getVipProgress, getLiveGameStats, getRecentWinnings, getUserBox } from "./services/tma/axiosTmaRequests";
import { getDictionaries, getPromotions, getLobbiesInfo, getPages, getContentVersion, getLoggedOutCampaigns, getVipData, getWebsiteBlocks, getWheelRewards } from "./services/strapi/axiosStrapiRequests";
import seoRelated from "./helpers/seo_helpers";
import { initIovation } from "./helpers/iovation_helpers";
// import * as Sentry from "@sentry/vue";
import ExcludedGamesList from "./assets/excludedGamesList";

export default {
    name: "App",
    data: function () {
        return {
            showloader: true,
            skin: null,
            initialCallFired: false,
            contentVersionCheckDone: false,
            evolutionGames: [],
        };
    },
    created: function () {
        //Getting required data
        this.skin = this.getSkin();
        if(this.skin){
            this.configStore.initConfig(this.skin);
        }
        if (this.skin === "ac" || this.skin === "ra") {
            // this.loadLicenceScript();

            getContentVersion(this.myStore, this.skin).then((latestVersion) => {
                const currentVersion = getCookie("contentVersion");
                if (currentVersion === "" || currentVersion !== `${latestVersion}`) {
                    // Clear persisted calls store data
                    createCookie("contentVersion", latestVersion, 10);
                    this.perCallsStore.$reset();
                    this.configStore.$reset();
                }
                this.contentVersionCheckDone = true;
                this.configStore.initConfig(this.skin);
                this.$forceUpdate();
            });

            getLocation(this.callsStore, this.myStore, this.$router, this.skin, this.perCallsStore).then((location) => this.$forceUpdate());

            if (getCookie("accessToken") !== "" && getCookie("expiresAt") !== "" && getCookie("refreshToken") !== "" && getCookie("userId") !== "") {
                getUser(getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.$router, this.skin, this.perCallsStore).then(() => {
                    getBalances(getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.$router, this.skin, this.perCallsStore);
                });
                setRefreshTokenTimer(getCookie("expiresAt"), getCookie("refreshToken"), getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.skin, this.perCallsStore);
            } else {
                logOut(this.callsStore, this.myStore, this.$router, true, this.skin, this.perCallsStore);
            }
        }
    },
    updated: function () {
        if (this.initialCallFired === false && this.callsStore.locationObject && this.contentVersionCheckDone) {
            // Language Related
            let defaultLanguage = getCookie("language");
            let defaultStrapiLocale = getCookie("cmsLanguageCode");

            if (defaultLanguage === "" || defaultLanguage === undefined || defaultLanguage === "undefined") {
                // If language is not yet set in cookies
                this.configStore.config.languages.some((language) => {
                    if (language.code === this.callsStore.countryCode) {
                        // And the user location matches one of our site local codes
                        defaultLanguage = language.code;
                        defaultStrapiLocale = language.strapiCode;
                        return true;
                    }
                });
            }

            // If language is still empty
            if (defaultLanguage === "" || defaultLanguage === undefined || defaultLanguage === "undefined") {
                defaultLanguage = "en";
                defaultStrapiLocale = "en";
            }

            if (getCookie("language") !== defaultLanguage) {
                this.perCallsStore.$reset();
                createCookie("language", defaultLanguage, 365);
                createCookie("cmsLanguageCode", defaultStrapiLocale, 365);
            }

            this.configStore.config.languages.some((language) => {
                if (language.code === defaultLanguage) {
                    this.configStore.addToConfig({ currentLanguage: language.language });
                    return true;
                }
            });

            // Getting all lobbies info from Strapi
            getLobbiesInfo(this.skin, this.perCallsStore, this.myStore).then((lobbiesInfo) => {
                if (lobbiesInfo.fromPersistedLocalStorage === false || this.perCallsStore.homeLobbyIdsArray.length < 1) {
                    let requiredLobbyIds = [];
                    lobbiesInfo.strapiLobbiesArray.map((lobbyInfo) => {
                        requiredLobbyIds.push(lobbyInfo.attributes.lobbyId);
                    });

                    // Getting lobbies from TMA (One by one as this was quicker)
                    if (requiredLobbyIds.length > 0) {
                        const requiredLobbyIdsLength = requiredLobbyIds.length;
                        let flag = 0;
                        let tmaLobbies = [];

                        requiredLobbyIds.map((id) => {
                            getLobbies(this.perCallsStore, this.skin, id, this.myStore, this.callsStore, this.$router).then((lobby) => {
                                flag = flag + 1;

                                if (lobby[0] && lobby[0].id.indexOf("all-games") !== -1) {
                                    let filteredGames = [];
                                    let evolutionGames = [];

                                    lobby[0].brandGames.map((game) => {
                                        // Filter Games
                                        if (this.includeGame(game)) {
                                            filteredGames.push(game);
                                        }

                                        //Find Evolution games for evoApi
                                        if (game.studio === "EVOLUTION") {
                                            evolutionGames.push(game);
                                        }
                                    });
                                    lobby[0].brandGames = filteredGames;
                                    this.perCallsStore.setEvolutionGamesArray(evolutionGames);
                                }

                                if (lobby[0]) {
                                    tmaLobbies.push(lobby[0]);
                                }

                                if (flag === requiredLobbyIdsLength) {
                                    this.createLobbiesOrder(lobbiesInfo.strapiLobbiesArray, tmaLobbies);
                                }
                            });
                        });
                    }
                } else {
                    // this.getLiveGameStats();
                }
            });
            getDictionaries(this.perCallsStore, this.myStore);
            getPages(this.perCallsStore, this.skin, this.$router, this.myStore);
            // getWebsiteBlocks(this.perCallsStore, this.skin, this.myStore);
            // if (this.configStore.config.wheelOfFortune) {
            //     getWheelRewards(this.perCallsStore, this.myStore, this.skin);
            //     if (this.myStore.loggedIn) {
            //         getUserBox(this.skin, this.myStore, this.perCallsStore, this.callsStore, this.$router);
            //     }
            // }
            // this.getRecentWinnings();

            //Required Calls
            if (this.callsStore.countryCode && (this.callsStore.countryCode !== "se" || this.configStore.config.allowBonusesInSE)) {
                setTimeout(
                    function () {
                        // if (this.configStore.config.vipPage) {
                        //     // Get VIP data from Strapi
                        //     getVipData(this.skin, this.perCallsStore, this.myStore);
                        //     if (this.myStore.loggedIn) {
                        //         // Get VIP Progress from TMA
                        //         getVipProgress(getCookie("userId"), this.skin, this.myStore, getCookie("accessToken"), this.perCallsStore, this.callsStore, this.$router);
                        //     }
                        // }

                        if (this.myStore.loggedIn) {
                            getUserCampaignBonuses(getCookie("userId"), this.skin, this.myStore, getCookie("accessToken"), this.perCallsStore, this.callsStore, this.$router).then((response) => {
                                if (response.success && response.campaignIdList) {
                                    getPromotions(this.perCallsStore, this.callsStore, response.campaignIdList, this.skin, this.$router, this.myStore);
                                }
                            });
                        } else {
                            getLoggedOutCampaigns(this.skin, this.perCallsStore, this.myStore).then((campaignIdList) => {
                                if (campaignIdList) {
                                    getPromotions(this.perCallsStore, this.callsStore, campaignIdList, this.skin, this.$router, this.myStore);
                                }
                            });
                        }
                    }.bind(this),
                    100,
                );
            } else {
                this.perCallsStore.setPromotionsObject({});
            }

            //GTM Initialisation and SEO Related
            // addGtmIFrame(import.meta.env.VITE_GTM_ID);
            // createGtm(import.meta.env.VITE_GTM_ID);
            seoRelated(this.configStore.config);
            // initIovation(import.meta.env.VITE_IOVATION_KEY);

            // LiveAgentInitialisation GTM
            // this.configStore.config.languages.some((language) => {
            //     if (language.code === defaultLanguage && language.defaultChatButtonId) {
            //         pushGtmEventObject({ event: this.configStore.config.liveAgentEvent, liveAgentId: language.defaultChatButtonId });
            //         return true;
            //     }
            // });

            // this.myStore.setIncognitoBrowserPopUp();
            this.showloader = false;
            this.initialCallFired = true;
            setTimeout(
                function () {
                    // Sentry.setTag("Skin", this.skin);
                    // Sentry.setContext("Additional Data", {
                    //     Skin: this.skin,
                    //     Language: defaultLanguage,
                    //     Country: this.callsStore.locationObject !== null ? this.callsStore.locationObject.country.name : "N/A",
                    //     CountryCode: this.callsStore.locationObject !== null ? this.callsStore.locationObject.country.code : "N/A",
                    //     Postcode: this.callsStore.locationObject !== null ? this.callsStore.locationObject.postcode : "N/A",
                    //     LoggedIn: this.myStore.loggedIn,
                    //     user: this.myStore.loggedIn ? this.callsStore.userObject : "No User Info",
                    // });
                    // if (this.myStore.loggedIn && this.callsStore.userObject) {
                    //     Sentry.setUser({ email: this.callsStore.userObject.email });
                    // }
                    // // Example: /?affid=12345&token=affiliateTest
                    // if (this.$router.currentRoute.value.query.affid) {
                    //     const affiliateId = this.$router.currentRoute.value.query.affid;
                    //     createCookie("affiliateId", affiliateId, 15);
                    //     Sentry.setTag("From Affiliate", true);
                    //     Sentry.setTag("Affiliate Id", affiliateId);
                    //     pushGtmEvent(`Website_Opened_${affiliateId}`, this.skin);
                    //     Sentry.withScope(
                    //         function (scope) {
                    //             scope.setLevel("info");
                    //             Sentry.captureMessage(`${this.skin.toUpperCase()}-UserFrom-${affiliateId}`);
                    //         }.bind(this),
                    //     );
                    // }
                    // if (this.$router.currentRoute.value.query.token) {
                    //     createCookie("affiliateToken", this.$router.currentRoute.value.query.token, 15);
                    // }
                    // HotjarInitialisation GTM
                    // if (this.configStore.config.hotjarSkinId) {
                    //     pushGtmEventObject({ event: "HotjarInitialisation", hotjarSkinId: this.configStore.config.hotjarSkinId });
                    // }
                    // PIX Gaming Script GTM (Tommy and Marvin asked to add this script for this particular affiliate)
                    // On all pages and all the time
                    // if (this.configStore.config.pixGamingScript) {
                    //     pushGtmEventObject({ event: "PixGamingScript", pixGamingScript: this.configStore.config.pixGamingScript });
                    // }
                }.bind(this),
                2000,
            );
        }
    },
    methods: {
        getSkin() {
            if (document.location.href.indexOf("temp1") !== -1) {
                return "ac";
            } else if (document.location.href.indexOf("temp2") !== -1) {
                return "ra";
            } else if (document.location.href.indexOf("localhost") !== -1 || document.location.href.indexOf("bs-local") !== -1) {
                return "ra";
            } else if (document.location.href.indexOf("pixelaspiration") !== -1) {
                return "px";
            } else {
                return "noSkin";
            }
        },
        // loadLicenceScript() {
        //     if (this.configStore.config.apgSeal) {
        //         loadAnyScript(this.configStore.config.apgSeal, "apgSeal", () => {});
        //     }

        //     if (this.configStore.config.xcmSeal) {
        //         loadAnyScript(this.configStore.config.xcmSeal, "xcmSeal", () => {});
        //     }

        //     if (this.configStore.config.anjSeal) {
        //         loadAnyScript(this.configStore.config.anjSeal, "anjSeal", () => {});
        //     }
        // },
        includeGame(game) {
            // These live games need to be enabled from TMA so, players will be able to play them.
            // But these games are going to be visible in evolution or pragmatic lobbies and not as a game thumbnail.
            // The reason is that there are a lot of similar game names like BlackJack 1,2,...30 etc

            //Excluded Games
            // if (ExcludedGamesList.indexOf(game.name) !== -1) {
            //     console.log(game.name);
            // }

            if (ExcludedGamesList.indexOf(game.name) === -1) {
                return true;
            }
            return false;
        },
        createLobbiesOrder(lobbiesInfo, tmaLobbies) {
            let homeLobbyIdsArray = [];
            let menuLobbyIdsArray = [];
            let casinoLobbyIdsArray = [];
            let liveCasinoLobbyIdsArray = [];
            let tmaLobbiesArray = [];

            lobbiesInfo.map((lobbyInfo) => {
                tmaLobbies.some((lobby) => {
                    if (lobby.id === lobbyInfo.attributes.lobbyId) {
                        if (lobbyInfo.attributes.position && Object.keys(lobbyInfo.attributes.position).length !== 0 && lobbyInfo.attributes.position.indexOf("Home") !== -1) {
                            homeLobbyIdsArray = [...homeLobbyIdsArray, lobby.id];
                        }

                        if (lobbyInfo.attributes.position && Object.keys(lobbyInfo.attributes.position).length !== 0 && lobbyInfo.attributes.position.indexOf("Menu") !== -1) {
                            menuLobbyIdsArray = [...menuLobbyIdsArray, { id: lobby.id, name: lobby.name }];
                        }

                        if (lobbyInfo.attributes.lobbyType && Object.keys(lobbyInfo.attributes.lobbyType).length !== 0 && lobbyInfo.attributes.lobbyType.indexOf("casino") !== -1) {
                            casinoLobbyIdsArray = [...casinoLobbyIdsArray, { id: lobby.id, name: lobby.name }];
                        }

                        if (lobbyInfo.attributes.lobbyType && Object.keys(lobbyInfo.attributes.lobbyType).length !== 0 && lobbyInfo.attributes.lobbyType.indexOf("liveCasino") !== -1) {
                            liveCasinoLobbyIdsArray = [...liveCasinoLobbyIdsArray, { id: lobby.id, name: lobby.name }];
                        }

                        tmaLobbiesArray = [...tmaLobbiesArray, lobby];
                    }
                });
            });
            this.perCallsStore.setHomeLobbyIdsArray(homeLobbyIdsArray);
            this.perCallsStore.setMenuLobbyIdsArray(menuLobbyIdsArray);
            this.perCallsStore.setCasinoLobbyIdsArray(casinoLobbyIdsArray);
            this.perCallsStore.setLiveCasinoLobbyIdsArray(liveCasinoLobbyIdsArray);
            this.perCallsStore.setTmaLobbiesArray(tmaLobbiesArray);
            // this.getLiveGameStats();
        },
        // getLiveGameStats() {
        //     setTimeout(
        //         function () {
        //             this.getLiveGameStats();
        //         }.bind(this),
        //         60000,
        //     );

        //     if (this.evolutionGames.length === 0) {
        //         this.perCallsStore.evolutionGamesArray.map((game) => {
        //             if (this.evolutionGames.indexOf(game.gameId) === -1 && game.gameId !== "live-golden-wealth-baccarat") {
        //                 this.evolutionGames.push(game.gameId);
        //             }
        //         });
        //     }
        //     getLiveGameStats(this.skin, '["' + this.evolutionGames.join('","') + '"]', this.myStore, this.perCallsStore, this.callsStore, this.$router);
        // },
        // getRecentWinnings() {
        //     var oneMonthAgo = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate());
        //     setTimeout(
        //         function () {
        //             this.getRecentWinnings(this.skin, "createdAt", oneMonthAgo, this.myStore, this.perCallsStore, this.callsStore, this.$router);
        //         }.bind(this),
        //         60000,
        //     );
        //     getRecentWinnings(this.skin, "createdAt", oneMonthAgo, this.myStore, this.perCallsStore, this.callsStore, this.$router);
        // },
        onBlackBackdropClick() {
            if (this.$router.currentRoute.value.fullPath.indexOf("/register") !== -1) {
                this.myStore.setCloseRegisterAttempt(true);
            } else if (this.$router.currentRoute.value.fullPath.indexOf("/vip") !== -1) {
                for (let i = 1; i < 31; i++) {
                    $(`#level-${i}-info`).removeClass("visible");
                }
                $(".black-backdrop").removeClass("visible");
            } else {
                this.myStore.setCloseRegisterAttempt(false);
                setSideMenuVisibility(false);
                if (this.$route.meta.showModal) {
                    setModalVisibility(false, this.$router);
                }
            }
        },
    },
};
</script>

<style lang="scss">
// When Side menu is opened
.black-backdrop {
    visibility: hidden;
    opacity: 0;
    transition: 0.15s all;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    &.visible {
        visibility: visible;
        opacity: 1;
    }
}
</style>

import { defineStore } from "pinia";
import { arrayContains } from "../helpers/helpers";

const skins = {
    px: {
        companyName: "Pixel Aspiration Ltd",
        email: "support@pixelaspiration.com",
        registrationAddress: "",
        contactNumber: "+356 79296475",
    },
    ac: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "Pixel Aspiration Ltd",
        licenceCountry: "Malta",
        registrationNumber: "12345",
        registrationAddress: "Qrendi, Malta",
        regulatedBy: "Pixel",
        licenceNumber: "678910",
        originalDomain: "www.pixelaspiration.com",
        originalName: "Pixel Aspiration",
        coolName: "Pixel",
        complaintsEmail: "support@pixelAspiration.com",
        privacyEmail: "support@pixelAspiration.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 12,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            // { code: "br", strapiCode: "pt-BR", language: "Português", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            // { code: "pl", strapiCode: "pl", language: "Polski", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            // { code: "fi", strapiCode: "fi", language: "Suomi", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            // { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
        ],
        // If we have a defaultCurrency, all users from that country will be registered with that defaultCurrency - ELSE EUR
        countries: [
            // { code: "ca", defaultCurrency: "CAD" },
            // { code: "br", defaultCurrency: "BRL" },
            // { code: "no", defaultCurrency: "NOK" },
            // { code: "pl", defaultCurrency: "PLN" },
            // { code: "gb", defaultCurrency: "GBP" },
        ],
        pixGamingScript: "",
        hotjarSkinId: "",
        welcomeOfferVideo: "", //WelcomeOfferVideo.mp4
        welcomeOffer: "Bottom", //RegisterForm, Left, Center, Bottom
        homeLobbyStyle: "netflix", // netflix, simple
        // vipPage: "pathView", // pathView, columnView
        headerType: "button", //button, icon
        modal: "middle", // middle, right
        gameThumbnailGrid: "normal", // small, normal
        gameThumbnailSize: "500-700", // 280-280, 500-700, 512-288
        thumbnailDetails: "inside", // inside, outside
        home: "one", // one, two, splash
        paymentsLayout: "row", // row, tile, rowTwo
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [
            { svg: "fa-1", title: "", description: "", titleKey: "AMON_SELLING_1", descriptionKey: "AMON_DESCRIPTION_1" },
            { svg: "fa-2", title: "", description: "", titleKey: "AMON_SELLING_2", descriptionKey: "AMON_DESCRIPTION_2" },
            { svg: "fa-3", title: "", description: "", titleKey: "AMON_SELLING_3", descriptionKey: "AMON_DESCRIPTION_3" },
        ],
        supportPointsBlocks: [],
        liveAgentEvent: "",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        // QUICKSPIN
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsBelow", // noDetails, detailsBelow, detailsRight
        formStyle: "old", // old, modern
        bannerRotator: "coverflow", // coverflow, slide
        wheelOfFortune: null,
    },
    ra: {
        apgSeal: "",
        xcmSeal: "",
        anjSeal: "",
        companyName: "Pixel Aspiration Ltd",
        licenceCountry: "Malta",
        registrationNumber: "12345",
        registrationAddress: "Qrendi, Malta",
        regulatedBy: "Pixel",
        licenceNumber: "678910",
        originalDomain: "www.pixelaspiration.com",
        originalName: "Pixel Aspiration",
        coolName: "Pixel",
        complaintsEmail: "support@pixelAspiration.com",
        privacyEmail: "support@pixelAspiration.com",
        autoExclusionOptions: ["PT24H", "P7D"],
        homeLobbyGames: 6,
        languages: [
            { code: "en", strapiCode: "en", language: "English", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            { code: "fr", strapiCode: "fr", language: "Français", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            // { code: "no", strapiCode: "nn-NO", language: "Norsk", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
            // { code: "fi", strapiCode: "fi", language: "Suomi", defaultChatButtonId: "", supportEmail: "support@pixelAspiration.com" },
        ],
        countries: [
            // { code: "ca", defaultCurrency: "CAD" },
            // { code: "no", defaultCurrency: "NOK" },
            // { code: "pl", defaultCurrency: "PLN" },
        ],
        // pixGamingScript: "pix_racasino",
        hotjarSkinId: "",
        welcomeOfferVideo: "",
        welcomeOffer: "Center",
        homeLobbyStyle: "simple",
        // vipPage: "pathView",
        headerType: "icon",
        modal: "right",
        gameThumbnailGrid: "normal",
        gameThumbnailSize: "512-288",
        thumbnailDetails: "inside",
        home: "one",
        paymentsLayout: "rowTwo",
        sellingBlocksStyle: "One",
        sellingPointsBlocks: [],
        supportPointsBlocks: [],
        liveAgentEvent: "",
        gameFeatures: ["Megaways", "Random", "Gamble", "Buy Feature", "Jackpot", "Multiplier", "Respins", "Walking Symbols", "Pick Objects"],
        gameProviders: ["EVOLUTION", "PRAGMATIC_PLAY", "PLAYNGO", "RED_TIGER", "PUSH_GAMING", "NETENT", "YGGDRASIL", "PLAYSON", "NOLIMIT_CITY", "RELAX", "RED_RAKE", "HACKSAW_GAMING", "SPEARHEAD", "GAMING_CORPS", "BIG_TIME_GAMING", "SWINTT", "FANTASMA", "PG_SOFT", "BOOMING", "WAZDAN", "NOVOMATIC"],
        paymentProviders: ["BANK_INTERAC", "CARD_PAYMENT_IQ", "CRYPTO_PAYMENT_IQ", "WALLET_MIFINITY", "WALLET_JETON", "WALLET_EZEEWALLET", "WALLET_APPLEPAY", "VOUCHER_CASHLIB", "VOUCHER_FLEXEPIN", "WALLET_CITIZEN"],
        affiliateProgram: null,
        promoThumbsWithDetails: "detailsBelow",
        formStyle: "old",
        bannerRotator: "slide",
        wheelOfFortune: null,
    },
};

// Features
const configPerFeature = {
    isSideMenuLayout: ["ra"],
    noSideMenu: [],
    shopFeatureEnabled: [],
    showTime: [],
    sideMenuBtnInBottomMobileMenu: [],
    allowBonusesInSE: [],
    blockPPGames: [],
    integratedModalBg: [],
    twoFA: [],
    homePagePromotions: [],
    altenarSportsBook: [],
};

const isFeatureEnabled = (feature, skin) => {
    return arrayContains(configPerFeature[feature], skin);
};

export const useConfigStore = defineStore("config", {
    state: () => ({
        config: null,
        registerData: null,
    }),
    actions: {
        initConfig(skin) {
            const initialConfigObject = {
                skin,
                apgSeal: skins[skin].apgSeal,
                xcmSeal: skins[skin].xcmSeal,
                anjSeal: skins[skin].anjSeal,
                originalDomain: skins[skin].originalDomain,
                originalName: skins[skin].originalName,
                coolName: skins[skin].coolName,
                complaintsEmail: skins[skin].complaintsEmail,
                privacyEmail: skins[skin].privacyEmail,
                companyName: skins[skin].companyName,
                licenceCountry: skins[skin].licenceCountry,
                registrationNumber: skins[skin].registrationNumber,
                registrationAddress: skins[skin].registrationAddress,
                regulatedBy: skins[skin].regulatedBy,
                licenceNumber: skins[skin].licenceNumber,
                autoExclusionOptions: skins[skin].autoExclusionOptions,
                homeLobbyGames: skins[skin].homeLobbyGames,
                languages: skins[skin].languages,
                countries: skins[skin].countries,
                pixGamingScript: skins[skin].pixGamingScript,
                hotjarSkinId: skins[skin].hotjarSkinId,
                welcomeOfferVideo: skins[skin].welcomeOfferVideo,
                welcomeOffer: skins[skin].welcomeOffer,
                homeLobbyStyle: skins[skin].homeLobbyStyle,
                vipPage: skins[skin].vipPage,
                modal: skins[skin].modal,
                isSideMenuLayout: isFeatureEnabled("isSideMenuLayout", skin),
                shopFeatureEnabled: isFeatureEnabled("shopFeatureEnabled", skin),
                gameThumbnailGrid: skins[skin].gameThumbnailGrid,
                gameThumbnailSize: skins[skin].gameThumbnailSize,
                thumbnailDetails: skins[skin].thumbnailDetails,
                showTime: isFeatureEnabled("showTime", skin),
                home: skins[skin].home,
                noSideMenu: isFeatureEnabled("noSideMenu", skin),
                sideMenuBtnInBottomMobileMenu: isFeatureEnabled("sideMenuBtnInBottomMobileMenu", skin),
                paymentsLayout: skins[skin].paymentsLayout,
                twoFA: isFeatureEnabled("twoFA", skin),
                sellingBlocksStyle: skins[skin].sellingBlocksStyle,
                sellingPointsBlocks: skins[skin].sellingPointsBlocks,
                supportPointsBlocks: skins[skin].supportPointsBlocks,
                liveAgentEvent: skins[skin].liveAgentEvent,
                gameFeatures: skins[skin].gameFeatures,
                gameProviders: skins[skin].gameProviders,
                paymentProviders: skins[skin].paymentProviders,
                headerType: skins[skin].headerType,
                affiliateProgram: skins[skin].affiliateProgram,
                homePagePromotions: isFeatureEnabled("homePagePromotions", skin),
                promoThumbsWithDetails: skins[skin].promoThumbsWithDetails,
                formStyle: skins[skin].formStyle,
                bannerRotator: skins[skin].bannerRotator,
                integratedModalBg: isFeatureEnabled("integratedModalBg", skin),
                blockPPGames: isFeatureEnabled("blockPPGames", skin),
                wheelOfFortune: skins[skin].wheelOfFortune,
                allowBonusesInSE: isFeatureEnabled("allowBonusesInSE", skin),
                altenarSportsBook: isFeatureEnabled("altenarSportsBook", skin),
                email: skins[skin].email,
                contactNumber: skins[skin].contactNumber,
            };
            this.config = Object.assign({}, this.config, initialConfigObject);
        },
        addToConfig(newValue) {
            this.config = Object.assign({}, this.config, newValue);
        },
        setRegisterData(newValue) {
            this.registerData = newValue ? Object.assign({}, this.registerData, newValue) : null;
        },
    },
    persist: true,
});
